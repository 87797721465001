export function showCustomHint(message, id) {
    //console.log('showCustomHint called with message:', message); // Debug log
    //console.log('showCustomHint called with id:', id);

    const hintElement = document.createElement('div');
    hintElement.className = 'custom-hint';
    hintElement.id = id;
    hintElement.innerText = message;

    //console.log('Appending hintElement to body:', hintElement); // Debug log
    document.body.appendChild(hintElement);

    setTimeout(() => {
        hintElement.classList.add('show');
        //console.log('Added "show" class to hintElement'); // Debug log
    }, 10); // Delay to allow CSS transition

    setTimeout(() => {
        hintElement.classList.remove('show');
        setTimeout(() => {
            document.body.removeChild(hintElement);
            //console.log('Removed hintElement from body'); // Debug log
        }, 300); // Transition duration
    }, 3000); // Display duration
}
